import React, {useEffect} from 'react';
import axios from 'axios'
import Layout from '../components/layout'

const PaymentSuccess = (props) => {      
    useEffect(async () => {    
        if(props.location.search) {
            let parts = props.location.search.split("=")
            if(parts[1]) {     
                const res = await axios.get(`https://api.mathcurious.com/checkout_session?sessionId=${parts[1]}`);
            }
        }        
    }, []);

    return (
      <Layout>     
          <article>
          <header>
            <h1 itemProp="headline">Thanks for Payment</h1>

            </header>
            <div className="pt-5"> 
                Thanks for your Payment. Your subscription is now active and you are granted the license to use all FREE resources available on Mathcurious for commercial purposes like online teaching, Outschool. 
                You are not allowed to copy, redistribute or resell any of these resources or their derivates. 
                
            </div>
            <div className="pt-2"> 
                Other terms of use listed at <a href="https://mathcurious.com/terms-of-use">https://mathcurious.com/terms-of-use</a> still apply.
            </div>
            
        </article>
        <div> 
            
        </div>
      </Layout>
    );  
}
export default PaymentSuccess;

